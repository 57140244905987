export const LG_TO_TXT = {
  "ar-SA": "Arabic",
  "bg-BG": "Bulgarian",
  "zh-CN": "Chinese",
  "zh-TW": "Chinese",
  "cs-CZ": "Czech",
  "da-DK": "Danish",
  "nl-NL": "Dutch",
  "en-US": "English",
  "en-GB": "English",
  "fi-FI": "Finnish",
  "fr-FR": "French",
  "de-DE": "German",
  "el-GR": "Greek",
  "he-IL": "Hebrew",
  "hi-IN": "Hindi",
  "hu-HU": "Hungarian",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "ja-JP": "Japanese",
  "ko-KR": "Korean",
  "pl-PL": "Polish",
  "pt-BR": "Portuguese",
  "nb-NO": "Norwegian",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  "sk-SK": "Slovak",
  "es-ES": "Spanish",
  "sv-SE": "Swedish",
  "th-TH": "Thai",
  "tr-TR": "Turkish",
}

export const DEFAULT_PR_SENTENCES = {
  "en-US": "Could I get a coffee please?",
  "en-GB": "No way! That's impossible",
  "zh-CN": "欢迎",
  "zh-TW": "欢迎",
  "ja-JP": "役に立たないことは何もしない",
  "de-DE": "Wer fremde Sprachen nicht kennt, weiß nichts von seiner eigenen",
  "es-ES": "Mi casa es tu casa",
  "it-IT": "Prenderò la specialità dello chef",
  "pt-BR": "Portuguese sentence",
  "fr-FR": "Ce croissant est délicieux",
  "ru-RU": "Добро пожаловать",
}

export const LATIN_LANGUAGES = [
  "bg-BG",
  "en-US",
  "en-GB",
  "de-DE",
  "fr-FR",
  "es-ES",
  "it-IT",
  "pt-BR",
  "hu-HU",
  "tr-TR",
  "cs-CZ",
  "da-DK",
  "nl-NL",
  "fi-FI"
]

//- Use Amazon Polly of Microsoft Azure
export const AWS_POLLYABLE = [
  'da-DK',
  'de-DE',
  'en-GB',
  'en-US',
  'es-ES',
  'fr-FR',
  'hi-IN',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nb-NO',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'ru-RU',
  'sv-SE',
  'tr-TR',
]
export const AZURE_TTS = [
  'hu-HU',
  'ar-SA',
  'zh-TW',
  'zh-CN'
]

export const AVAILABLE_LANGUAGES = Object.keys(LG_TO_TXT)


export const FRENCH_IPA = [
  'i',
  'e',
  'ɛ',
  'a',
  'y',
  'ø',
  'œ',
  'u',
  'o',
  'ɔ',
  'ɑ',
  'ə',
  'ɛ̃',
  'œ̃',
  'õ',
  'ɑ̃',
  'ɥ',
  'w',
  'j',
  'p',
  'b',
  't',
  'd',
  'k',
  'g',
  'f',
  'v',
  'l',
  's',
  'z',
  'ʃ',
  'ʒ',
  'm',
  'n',
  'ɲ',
  'ŋ',
  'ʁ'
]
export const SHORT_FRENCH_IPA = [
  'ɛ',
  'ø',
  'œ',
  'ɔ',
  'ə',
  'ɛ̃',
  'œ̃',
  'õ',
  'ɑ̃',
  'ɥ',
  'w',
  'ʃ',
  'ʒ',
  'ɲ',
  'ŋ',
  'ʁ'
]
export const SHORT_BRITISH_IPA = [
  'ɪ',
  'æ',
  'ʌ',
  'ɒ',
  'ʊ',
  'ə',
  'ɔː',
  'ɜː',
  'eɪ',
  'aɪ',
  'ɔɪ',
  'əʊ',
  'aʊ',
  'ɪə',
  'eə',
  'ʊə',
  'eɪə',
  'aɪə',
  'ɔɪə',
  'əʊə',
  'aʊə',
  'ŋ',
  'θ',
  'ð',
  'ʃ',
  'ʒ',
  'tʃ',
  'dʒ',
]

export const BRITISH_IPA = [
  'ɪ',
  'e',
  'æ',
  'ʌ',
  'ɒ',
  'ʊ',
  'ə',
  'iː',
  'ɑː',
  'ɔː',
  'uː',
  'ɜː',
  'eɪ',
  'aɪ',
  'ɔɪ',
  'əʊ',
  'aʊ',
  'ɪə',
  'eə',
  'ʊə',
  'eɪə',
  'aɪə',
  'ɔɪə',
  'əʊə',
  'aʊə',
  'ŋ',
  'θ',
  'ð',
  'ʃ',
  'ʒ',
  'tʃ',
  'dʒ',
  'j'
]

export const SHORT_AMERICAN_IPA = [
  'ɪ',
  'æ',
  'ɑ',
  'ɛ',
  'ʊ',
  'ɔ',
  'ə',
  'ʌ',
  'ɚ',
  'ɝ',
  'ɪr',
  'ɛr',
  'ɔr',
  'ɑr',
  'aɪ',
  'aʊ',
  'ɔɪ',
  'eɪ',
  'oʊ',
  'ŋ',
  'θ',
  'ð',
  'ʃ',
  'ʒ',
  'tʃ',
  'dʒ',
  'ɹ'
]

export const IPA_TO_WORD_EN_GB = {
  'ɪ': "<b>i</b>f",
  'e': "w<b>e</b>nt",
  'æ': "c<b>a</b>t",
  'ʌ': "f<b>u</b>n",
  'ɒ': "t<b>o</b>p",
  'ʊ': "b<b>oo</b>k",
  'ə': "<b>a</b>live",
  'ŋ': "thi<b>ng</b>",
  'θ': "<b>th</b>ink",
  'ð': "<b>th</b>ose",
  'ʃ': "<b>sh</b>irt",
  'ʒ': "delu<b>s</b>ion",
  'iː': "n<b>ee</b>d",
  'ɑː': "c<b>a</b>r",
  'ɔː': "l<b>a</>w",
  'uː': "fr<b>ui</b>t",
  'ɜː': "t<b>u</b>rn",
  'eɪ': "f<b>a</b>ce",
  'aɪ': "m<b>y</b>",
  'ɔɪ': "j<b>oy</b>",
  'əʊ': "n<b>o</b>",
  'aʊ': "h<b>ou</b>se",
  'ɪə': "cl<b>ea</b>r",
  'eə': "th<b>e</b>re",
  'ʊə': "fl<b>ow</b>er",
  'tʃ': "<b>ch</b>eese",
  'dʒ': "<b>j</b>uice",
  'eɪə': "",
  'aɪə': "",
  'ɔɪə': "",
  'əʊə': "",
  'aʊə': "",
  'j': "<b>y</b>ellow",
  "v": "<b>v</b>isit",
  "s": "<b>s</b>it",
  "z": "<b>z</b>oo",
  "r": "<b>r</b>ed",
  "d": "<b>d</b>ay",
  "n": "<b>n</b>ice",
  "l": "<b>l</b>evel"
}
export const IPA_TO_WORD_EN_US = {
  'ɪ': "<b>i</b>f",
  'e': "w<b>e</b>nt",
  'æ': "c<b>a</b>t",
  'ʌ': "f<b>u</b>n",
  'ɒ': "t<b>o</b>p",
  'ʊ': "b<b>oo</b>k",
  'ə': "<b>a</b>live",
  'ŋ': "thi<b>ng</b>",
  'ɑ': "f<b>a</b>ther",
  'ɛ': "<b>e</b>very",
  'ɔ': "c<b>a</b>ll",
  'ɚ': "nev<b>er</b>",
  'ɝ': "f<b>ir</b>st",
  'ɪr': "h<b>er</b>e",
  'ɛr': "<b>air</b>port",
  'ɔr': "<b>or</b>der",
  'ɑr': "<b>ar</b>t",
  'aɪ': "t<b>i</b>me",
  'aʊ': "d<b>ow</b>n",
  'ɔɪ': "p<b>oi</b>nt",
  'eɪ': "m<b>a</b>ke",
  'oʊ': "b<b>o</b>th",
  'θ': "<b>th</b>ink",
  'ð': "<b>th</b>ose",
  'ʃ': "<b>sh</b>irt",
  'ʒ': "delu<b>s</b>ion",
  'tʃ': "<b>ch</b>eese",
  'dʒ': "<b>j</b>uice",
  'ɹ': "<b>r</b>ight",
  'w': "<b>w</b>alk",
  "v": "<b>v</b>isit",
  "s": "<b>s</b>it",
  "z": "<b>z</b>oo",
  "r": "<b>r</b>ed",
  "d": "<b>d</b>ay",
  "n": "<b>n</b>ice",
  "l": "<b>l</b>evel"
}


export const AMERICAN_IPA = [
  'i',
  'ɪ',
  'u',
  'æ',
  'ɑ',
  'ɛ',
  'ʊ',
  'ɔ',
  'ə',
  'ʌ',
  'ɚ',
  'ɝ',
  'ɪr',
  'ɛr',
  'ɔr',
  'ɑr',
  'aɪ',
  'aʊ',
  'ɔɪ',
  'eɪ',
  'oʊ',
  'ŋ',
  'θ',
  'ð',
  'ʃ',
  'ʒ',
  'tʃ',
  'dʒ',
  'j',
  'ɹ'
]
