<template>
  <div id="app" :class="[browser.name, browser.os.toLowerCase()]">
    <component :is="layout"/>
    <feedback-box id="box_feedback" v-if="user.id && $route.path != '/' && $route.path != '/pricing'" />
  </div>
</template>

<script>
const { detect } = require('detect-browser');
const browser = detect();
import { mapGetters } from "vuex";
import NoLayout from "@/components/NoLayout";
import FrontLayout from "@/components/FrontLayout";
import AuthLayout from "@/components/AuthLayout";
import LoggedInLayout from "@/components/LoggedInLayout";
import feedbackBox from "@/components/FeedbackBox";
import npsBox from "@/components/NpsBox";
import UuidRandom from "uuid-random";

export default {
  name: "App",
  components: {
    "no-layout": NoLayout,
    "front-layout": FrontLayout,
    "auth-layout": AuthLayout,
    "logged-in-layout": LoggedInLayout,
    feedbackBox,
    npsBox
  },
  data() {
    return {
      browser,
    }
  },
  computed: {
    ...mapGetters(["user"]),
    layout() {
      return this.$store.getters.layout;
    }
  },
  sockets: {
    LESSON_CREATED(msg) {
      if (msg.student_id == this.user.id) {
        this.$store.dispatch('lastLesson').then( lesson => {
          this.$router.push(`/lessons/${lesson.uuid}`)
        });
        this.$toast.open({
          duration: 3000,
          message: `Redirecting to new lesson...`,
          type: "success"
        });
      }
    },
    SOCKET_LESSON_CREATED() {
      // console.log('SOCKET_LESSON_CREATED socket from app.vue')
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.refreshed) {
      // BYPASS cache
      this.$nextTick(() => {
        const scriptTags = document.querySelectorAll('script[src]');
        const cacheBuster = 'cacheBuster=' + new Date().getTime();

        for (const script of scriptTags) {
          const originalSrc = script.getAttribute('src');
          if (originalSrc[0] === '/') {
            script.remove();

            const newScript = document.createElement('script');
            newScript.src = originalSrc + '?' + cacheBuster;
            document.head.appendChild(newScript);
          }
        };
      })
    }
  },
  created() {
    // PARTNER
    if (this.$route.query.p_aid && !this.$localStorage.get("p_aid")) {
      this.$localStorage.set("pid", this.$route.query.p_aid);
    }

    this.$store.dispatch("setUuid", UuidRandom());

    this.$auth.load()
        .then(() => {
      if (this.$localStorage.get("redirectAfterLogin") == 'undefined') {
        this.$localStorage.set("redirectAfterLogin", null);
      }

      if (this.$auth.check()) {
        if (this.$auth.user()) {
          const user = this.$auth.user();
          this.$store.dispatch("setUser", user);
          this.$mixpanel.identify(user.id+'');
          this.$mixpanel.people.set({
            "$first_name": user.first_name,
            "$email": user.email,
            "$created": user.created_at,
            "$last_login": new Date()
          })
          this.$store.dispatch("setLayout", "logged-in-layout");
        } else {
        }
      }
    }).catch((e) => {
      console.log('USER NOT LOGGED IN')
      console.log({e})
    });
  }
};
</script>

<style lang="scss">
/*@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700');*/
@import "colors";
@import "~bulma/sass/utilities/_all";
@import "~bulma-pricingtable/dist/css/bulma-pricingtable";
@import "~bulma-timeline/dist/css/bulma-timeline";
$material-icons-font-path: "~material-icons/iconfont/";
$material-icons-css-prefix: "mdi" !default;
@import "~material-icons/iconfont/material-icons.scss";
@import "~material-icons/css/material-icons.scss";
@import 'helpers';
@import 'css/typography';

// Set your colors
//$primary: #1C7C9C
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

$border: #f2f2f2;
$modal-card-head-background-color: #FFF;
$modal-background-background-color: rgba(10, 10, 10, 0.56);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "action": (
    $purple,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "secondary": (
    #B7B7B7,
    $light-invert
  ),
  "dark": (
    $primaryDarker,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
    "highlight": (
      $highlight,
      $warning-invert
    ),
  "twitter": (
    $twitter,
    $twitter-invert
  )
);

$themeColor: $primaryDarker;
@import '~vue-slider-component/lib/theme/default.scss';


// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma/bulma";
@import "~buefy/src/scss/buefy";
@import "~bulma-utilities/sass/bulma-utilities";

body .v-toast__item--info {
  background-color: $primary;
}

.hover-brighten-95:hover {
  filter: brightness(95%);
}

.timeline .timeline-item .timeline-marker.is-icon {
  height: 1.8em;
  width: 1.8em;
  left: -0.85em;
}

.timeline .timeline-item .timeline-marker.is-icon > i {
  font-size: inherit !important;
}

.has-margin-auto {
  margin: auto;
}

.page-home canvas {
  margin: auto;
}

.position-relative {
  position: relative;
}

.primary-border {
  border: 1px solid $primary;
  border-radius: 2px;
}

.only-on-mac {
  display: none !important
}
.mac.os .only-on-mac {
  display: block !important
}

.has-transparent-input-bg {
  .textarea, .radio.button {
    background-color: $lightGray;
    &.is-success {
      background-color: #21c65b;
    }
  }
}

.fw-400 {
  font-weight: 400 !important;
}

.navbar-item img {
   max-height: inherit;
}

.my-progress canvas {
  width: 100% !important;
  margin: auto;
}
.my-progress canvas#doughnut-chart {
  max-width: 200px;
}

.content h1,
.content h2,
.content h3 {
  font-weight: 400;
}

// animation on scroll

.animate {
  transition-delay: .1s;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
}
/*.delayed-0s { transition-delay: 0s; }*/
.delayed-col-1-3 { transition-delay: .3s; }
.delayed-col-2-3 { transition-delay: .6s; }
.delayed-col-3-3 { transition-delay: .9s; }
.delayed-col-3-3-a { transition-delay: 1.2s; }

.slide-from-right {
  transform: translateX(20px);
  opacity: 0;
}

.slide-from-right.animate-active {
  transform: translateX(0);
  opacity: 1;
}

.has-nice-th {
  .th-wrap {
    text-align: left;
    color: $midGray;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    padding-top: 4px;
  }
}

// Animations

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-enter-from, .fade-leave-to {
  opacity: 0;
}

// ////

.live-lesson-page {
  .dropdown.is-top-right .dropdown-menu {
    bottom: 128%;
    width: 100%;
  }
  .dropdown-trigger {
    width: 100%;
  }
  .is-primary.is-inverted:hover {
    color: $white;
    background-color: $primary;
  }
  .is-info.is-inverted:hover {
    color: $white;
    background-color: $info;
  }
  .is-white:not(.card-header-icon):hover {
    color: $white;
    background-color: #0a0a0a;
  }
}

@media (max-width: 768px) {
  .live-lesson-page {
    .card-header {
      justify-content: flex-end !important;
    }
    .card-content {
      padding: 0.8rem;
    }
    .share-wrapper {
      height: 56px;
    }
    .column-recorder {
      display: flex;
      height: 70px;
    }
    .column.is-narrow.pr-0 {
      padding-right: 0.75rem !important;
    }
    .sentence--delete {
      width: 100%;
    }
    .sentence--delete,
    .sentence--listen {
      .button { width: 100% }
    }
  }
}


.is-capitalized-first-letter:first-letter {
  text-transform: uppercase;
}

.dropdown-menu .has-link #export_to_excel {
  color: #4a4a4a;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

.b-table.with-search {
  table {
    position: relative;
    tr:nth-child(2) {
      th { height: 0px; padding: 0; border-width: 1px}
    }

    th:nth-child(2) .control,
    th:first-child .control {
      position: absolute;
      left: 81px;
      top: 3px;
      input {
        padding-left: 1.3rem;
        min-width: 215px;
        height: 23px;
        font-size: 10px;
      }
      &:before {
        position: absolute;
        top: 7px;
        left: 7px;
        z-index: 2;
        content: "search";
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 12px;
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-feature-settings: 'liga';
        font-feature-settings: 'liga';
      }
    }
    th:nth-child(2) .control {
      left: 115px;
    }
  }
}

.pt-22px {
  padding-top: 22px;
}
@media (max-width: 1281px) {
  .my-progress canvas {
    width: 100% !important;
    margin: auto;
  }
  .my-progress canvas#doughnut-chart {
    max-width: 200px;
  }
}

@media (max-width: 1024px) {
  .navbar-start {
    text-align: left;
    a.navbar-item, .navbar-link {
      cursor: pointer;
      align-items: center;
      justify-content: start;
      display: flex;
    }
    i.mdi {
      vertical-align: middle;
    }
  }
}

.v-cal {
  padding: 0;
}

html {
  background-color: #f2f6fa;
  overflow-y: overlay;
}
html, body {
  scroll-behavior: smooth;
}


#app {
  background-color: #FFF;
  //font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  font-size: 15px;
}

.wiggles {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.mouse {
  width: 2.5rem;
  height: auto;
}

.scroll {
  animation-name: scroll;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform, opacity;
  opacity: 1;
}

.scroll-link {
  position: absolute;
  bottom: 2rem;
  right: 6rem;
  transition: opacity .5s ease;
  //transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .scroll-link {
    bottom: 0;
    right: 2rem;
  }
}

@keyframes scroll {

  0%, 20% {
    transform: translateY(0) scaleY(1);
  }

  60% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(36px) scaleY(1);
    opacity: 0;
  }

}

.plan-price-amount sup {
  font-size: 1rem;
}

.live-edit input {
  border: 0 none;
  box-shadow: none !important;
}
.card-header-title .live-edit input {
  font-weight: 700;
  font-size: 14px;
}
.lesson__title,
.lesson__title .live-edit  {
  width: 100%;
}
.has-valign-middle {
  vertical-align: middle;
}
h1,
h2,
h3 {
  font-family: 'Dela Gothic One', cursive;
  /*font-weight: 300;*/
  &.title {
    /*font-weight: 300;*/
  }
  &.bold {
    font-weight: 700;
  }
}
.text-title {
  font-family: 'Dela Gothic One', cursive;
}
.text-regular {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.text-system {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
.text-main {
  font-family: 'Lexend', sans-serif;
}

.opacity-8 {
  opacity: 0.8;
}

.has-justify-between {
  justify-content: space-between;
}
.has-justify-around {
  justify-content: space-around;
}

.mdi-30px {
  font-size: 30px;
}

.scale-on-click {
  transition: all .1s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.95);
  }
}

.title {
  font-size: 1.2rem;
  font-weight: 400;
}
.page--front .title {
  font-size: 1.6rem;
}

.spacer.is-1 {
  height: 1rem;
}

.spacer {
  display: block;
  margin: 0;
  padding: 0;
  height: 0;
  background: none;
  border: none;
}

hr.separator {
  height: 1px;
  clear: both;
  width: 100%;
  margin: 0px auto 10px;
}
.on-separator {
  background-color: #fff;
  padding: 0px 10px;
  position: relative;
  display: block;
  width: 70px;
  margin: -24px auto 20px;
}
hr.separator:not(.idx-0) {
  margin-top: 40px;
}

.modal {
  z-index: 100;
}
.columns.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.malign {
  vertical-align: middle;
}
.valign {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.halign {
  justify-content: center;
}
.nvalign>* {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.mt12 {
  margin-top: 12px;
}
.mt20 {
  margin-top: 20px;
}

.mb12 {
  margin-bottom: 12px;
}
.mb20 {
  margin-bottom: 20px;
}

.has-light-background,
.hero.is-success {
  background-color: #F8F7FD;
}

.hero.is-warning div.card-footer-item,
.hero.is-primary div.card-footer-item {
  color: inherit;
}

.modal .animation-content {
  overflow: visible;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ptb0,
.pbt0 {
  padding-top: 0;
  padding-bottom: 0;
}

.faded {
  opacity: 0.3;
}

.faded:hover {
  opacity: 1;
}

.is-wide {
  flex-grow: 1;
}

.text-align-right {
  text-align: right;
}

.image.is-200x200 {
  height: 200px;
  width: 200px;
}

.mw-70px {
  min-width: 70px;
}
.mw-110px {
  min-width: 110px;
}
.d-none-empty:empty {
  display: none !important;
}

.has-smooth-transform .icon,
.has-smooth-transform {
  transition: transform .4s ease;
}
.has-smooth-animation .icon,
.has-smooth-animation {
  transition: all .4s ease;
}
.has-text-underlined {
  text-decoration: underline;
}
.has-flex-grow-1 {
  flex-grow: 1;
}
.has-flex-wrap {
  flex-wrap: wrap;
}
.has-justified-center {
  justify-content: center;
}
.has-justified-start {
  justify-content: start;
}
.has-justify-start {
  justify-content: start;
}
.has-justify-end {
  justify-content: flex-end;
}
.has-space-around {
  justify-content: space-around
}
.has-space-between {
  justify-content: space-between
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0.3;
    transform: scale(0.5);
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

body {
.has-loader.is-loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;
  opacity: 0.5;
}
.has-loader.is-loading:after {
  -webkit-animation: spinAround 500ms infinite linear;
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
  position: absolute;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
  position: absolute !important;
}
.is-primary.is-outlined.is-loading:hover:after,
.is-primary .has-loader.is-loading:after {
  border: 2px solid #fff;
  border-color: transparent transparent #fff #fff !important
}
.primary-loader.has-loader.is-loading:after {
  border-color: transparent transparent #FF5B62 #FF5B62 !important;
}
}
.modal-content, .modal-card {
  width: auto;
}
.modal-content[style] {
  width: 100%
}
/*.has-loader.is-primary.is-outlined.is-loading:after {*/
  /*border-color: transparent transparent #FF5B62 #FF5B62 !important;*/
/*}*/
.is-smooth,
.is-smoothly-animated {
  transition: all 0.3s ease;
}

#share_modal {
  .button {
    min-width: 78px;
  }
  .has-text-success {
    .input {
      color: $success !important
    }
  }
}

.no-b {
  border: 0 none;
}

.has-shadow {
  /*-webkit-box-shadow: 0 2px 24px #cecdcd;*/
  /*box-shadow: 0 2px 24px #cecdcd;*/
  /*box-shadow: 0 2px 4px rgba(147, 91, 255, 0.33);*/
  /*box-shadow: 0 2px 24px #cecdcd, 0 2px 4px rgba(147, 91, 255, 0.33);*/

  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
}
.has-shadow-dark {
  box-shadow: 0px 2px 14px #212121;
}
.has-shadow-soft-dark {
  box-shadow: 0px 2px 14px rgba(34, 34, 34, 0.2);
}

.avatar img {
  padding: 5px;
  background: #fff;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.mdi-10px {
  font-size: 10px !important;
}
.mdi-12px {
  font-size: 12px !important;
}
.mdi-14px {
  font-size: 14px !important;
}
.mdi-16px {
  font-size: 16px !important;
}
.mdi-18px {
  font-size: 18px !important;
}
.mdi-20px {
  font-size: 20px !important;
}
.mdi-36px {
  font-size: 36px !important;
}
.mdi-48px {
  font-size: 48px !important;
}

.mdi-spin,
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.mdi-eye:before {
  content: "\E417";
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.is-small {
  i {
    font-size: 16px;
  }
}

.is-small-dropdown .dropdown-menu {
  min-width: 4rem;
  text-align: center;
  a.dropdown-item,
  .has-link a {
    padding-right: 1rem;
  }
}

.b-radio.radio input[type=radio] + .check {
  min-width: 1.25em;
}

.card-header {
  .button {
    height: 56px;
  }
}
.card-header .modal-card-body {
  .title {
    color: #363636;
  }
  .button {
    height: auto;
  }
}

.mw-32px {
  min-width: 32px;
}

.live-lesson-page .modal-card-body,
.live-lesson-page .modal-card {
  overflow: visible;
}

body {
  .dg-btn--ok {
    color: $primary;
  }
  .dg-btn--ok {
    border-color: $primary;
  }
  .dg-btn--cancel {
    background-color: $primary;
  }

  .dg-btn-loader .dg-circle {
    background-color: $primary;
  }
}

.is-disabled {
  opacity: 0.7;
  cursor: not-allowed;
  /*pointer-events: none;*/
}
.is-unclickable {
  pointer-events: none
}

.is-medium {
  .mdi {
    /*font-size: 18px !important;*/
  }
}

i[class*="flag"] {
  width: 24px;
  height: 24px;
  background-size: cover !important;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  &.is-small {
    width: 16px;
    height: 16px;
  }
}
i.flag-ar-SA {
  background: url("~@/assets/flags/ar-SA.png") no-repeat;
}
i.flag-bg-BG {
  background: url("~@/assets/flags/bg-BG.png") no-repeat;
}
i.flag-tr-TR {
  background: url("~@/assets/flags/tr-TR.png") no-repeat;
}
i.flag-cs-CZ {
  background: url("~@/assets/flags/cs-CZ.png") no-repeat;
}
i.flag-da-DK {
  background: url("~@/assets/flags/da-DK.png") no-repeat;
}
i.flag-el-GR {
  background: url("~@/assets/flags/el-GR.png") no-repeat;
}
i.flag-fi-FI {
  background: url("~@/assets/flags/fi-FI.png") no-repeat;
}
i.flag-hi-IN {
  background: url("~@/assets/flags/hi-IN.png") no-repeat;
}
i.flag-id-ID {
  background: url("~@/assets/flags/id-ID.png") no-repeat;
}
i.flag-ja-JP {
  background: url("~@/assets/flags/ja-JP.png") no-repeat;
}
i.flag-nb-NO {
  background: url("~@/assets/flags/nb-NO.png") no-repeat;
}
i.flag-nl-NL {
  background: url("~@/assets/flags/nl-NL.png") no-repeat;
}
i.flag-pl-PL {
  background: url("~@/assets/flags/pl-PL.png") no-repeat;
}
i.flag-ro-RO {
  background: url("~@/assets/flags/ro-RO.png") no-repeat;
}
i.flag-sk-SK {
  background: url("~@/assets/flags/sk-SK.png") no-repeat;
}
i.flag-sv-SE {
  background: url("~@/assets/flags/sv-SE.png") no-repeat;
}
i.flag-th-TH {
  background: url("~@/assets/flags/th-TH.png") no-repeat;
}
i.flag-fr-FR {
  background: url("~@/assets/flags/fr-FR.png") no-repeat;
}
i.flag-ko-KR {
  background: url("~@/assets/flags/ko-KR.png") no-repeat;
}
i.flag-he-IL {
  background: url("~@/assets/flags/he-IL.png") no-repeat;
}
i.flag-en-GB {
  background: url("~@/assets/flags/en-GB.png") no-repeat;
}
i.flag-en-US {
  background: url("~@/assets/flags/en-US.png") no-repeat;
}
i.flag-hu-HU {
  background: url("~@/assets/flags/hu-HU.png") no-repeat;
}
i.flag-zh-CN {
  background: url("~@/assets/flags/zh-CN.png") no-repeat;
}
i.flag-zh-TW {
  background: url("~@/assets/flags/zh-TW.png") no-repeat;
}
i.flag-es-ES {
  background: url("~@/assets/flags/es-ES.png") no-repeat;
}
i.flag-ru-RU {
  background: url("~@/assets/flags/ru-RU.png") no-repeat;
}
i.flag-it-IT {
  background: url("~@/assets/flags/it-IT.png") no-repeat;
}
i.flag-pt-PT {
  background: url("~@/assets/flags/pt-PT.png") no-repeat;
}
i.flag-pt-BR {
  background: url("~@/assets/flags/pt-BR.png") no-repeat;
}
i.flag-de-DE {
  background: url("~@/assets/flags/de-DE.png") no-repeat;
}

.dropdown-menu {
  z-index: 50;
}

.pt0 {
  padding-top: 0 !important;
}

span.icon + span {
  padding-left: 6px;
}

.table {
  text-align: left;
}

.tooltip-content:empty {
  display: none !important
}

// WYSIWYG

.page-my-account .ProseMirror h1 { font-size: 1.5em; }
.page-my-account .ProseMirror h2 { font-size: 1.2em;  }
.page-my-account .ProseMirror h3 { font-size: 1em; }
.page-my-account .ProseMirror h2:not(:first-child) { margin-top: 0.8em; }
.page-my-account div[contenteditable] {
  min-height: 433px;
  padding: 20px;
  background-color: rgba(222, 222, 222, 0.16);
  border-radius: 10px;
}
.page-my-account div[contenteditable]:focus {
  outline: none;
  box-shadow: 0 2px 4px rgba(147, 91, 255, 0.33);

}

// BOOKING

.all-day:first-child {
  color: #FFF;
}
.v-cal-content.v-cal-content--week
  .v-cal-days
  .v-cal-days__wrapper
  .v-cal-day__hour-block,
.v-cal-content.v-cal-content--day
  .v-cal-days
  .v-cal-days__wrapper
  .v-cal-day__hour-block,
.v-cal-content.v-cal-content--week .v-cal-times .v-cal-hour,
.v-cal-content.v-cal-content--day .v-cal-times .v-cal-hour {
  /*padding: 10px;*/
}
.v-cal-event-item {
  transition: background-color 0.3s ease;
}
.v-cal-event-item:not(.has-id) {
  cursor: crosshair;
}
.v-cal-event-item.has-id {
  background-color: $success;
  color: $success;
  cursor: crosshair;
  &::after {
    display: none;
  }
  &.has-state-pending {
    background-color: $info;
    color: $info;
    cursor: not-allowed;
  }
  &.has-state-booked {
    background-color: $info;
    color: $info;
  }
}
.availabilities {
  .v-cal-event-item {
    &.has-state-booked {
      background-color: $darkGreen;
      color: $darkGreen;
    }
    &.has-state-canceled {
      background-color: #b13f44;
      color: #b13f44;
      cursor: not-allowed;
    }
  }
}
.student-is-booking {
  .all-day {
    display: none !important;
  }

  .v-cal-event-item {
    cursor: pointer;
    &.has-state-free {
      background-color: rgba(22, 125, 240, 0.27);
      color: rgba(22, 125, 240, 0.27);
    }
    &.has-state-pending {
      cursor: crosshair;
      background-color: $info;
      color: $info;
    }
    &.has-state-booked {
      cursor: not-allowed;
      background-color: $success;
      color: $success;
    }
    &.has-state-canceled {
      cursor: not-allowed;
      background-color: $danger;
      color: $danger;
    }
  }
}

.v-cal-content--month.v-cal-content .v-cal-event-item {
  padding: 0px 14px;
}
.v-cal-content.v-cal-content--week
  .v-cal-days
  .v-cal-days__wrapper
  .v-cal-day__hour-block,
.v-cal-content.v-cal-content--day
  .v-cal-days
  .v-cal-days__wrapper
  .v-cal-day__hour-block {
  &:nth-child(8),
  &:nth-child(9) {
    background-color: $sweetGray;
  }
}

.onboard-tooltip-wrapper {
  display: inline-block;
  position: absolute;
  z-index: 10;
}
.pulsating-beacon {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  animation: fadein 3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
.beacon-pulse {
  border-radius: 50px;
  background: rgba(255, 45, 96, .4);
  border: 1px solid $primary;
  -webkit-animation: onboard-hotspot-pulse 2s infinite;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 3s;
  -ms-animation: onboard-hotspot-pulse 2s infinite;
  -ms-animation-fill-mode: forwards;
  -ms-animation-delay: 3s;
  -o-animation: onboard-hotspot-pulse 2s infinite;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 3s;
  animation: onboard-hotspot-pulse 2s infinite;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
.beacon-pulse,
.beacon-static {
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: $primary;
  border-radius: 50px;
}
.onboard-tooltip-wrapper>.tooltip-card .caret {
  top: -8px;
  left: 15px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $primary;
}
.onboard-tooltip-wrapper>.tooltip-card>.description {
  display: block;
  font-size: 16px 20px;
  color: #fff;
  line-height: 20px;
  margin-bottom: 8px;
}
.onboard-tooltip-wrapper>.tooltip-card {
  display: none;
  width: 320px;
  min-height: 120px;
  padding: 14px 18px;
  text-align: left;
  position: absolute;
  z-index: 2;
  border-radius: 5px;
  background: $primary;
}
.onboard-tooltip-wrapper>.tooltip-card>.tooltip-actions {
  display: inline-block;
  width: 100%;
  margin-top: 9px;
}
.leading-tight {
  line-height: 1.1;
}

@media print {
  .d-none-on-print,
  textarea:placeholder-shown,
  input[type="text"]:placeholder-shown,
  .card-footer a,
  #export_to_excel,
  #box_nps,
  #box_feedback,
  .lock-status,
  iframe,
  .card-header > span,
  nav.navbar {
    display: none !important;
  }

  .printclearboth {
    clear: both;
    height: 11px;
  }

  .textarea:not([rows]) {
    min-height: 50px;
  }
  .has-light-background {
    background: transparent !important;
  }

  #btn_add_sentence {
    display: block;
    clear: both;
    height: 0;
    padding: 0;
    margin: 0;
  }

  .container {
    width: 100%;
  }

  .sentence.columns {
    position: relative;
    /*width: 100%;*/
    /*float: left;*/
    /*.column:not(.is-three-quarters) > div {*/
    /*height: 100%;*/
    /*}*/
    .textarea {
      height: 100%;
    }
  }
  .sentence__translation input {
    color: #212121 !important;
  }
  html {
    font-size: 14px;
  }

  .button {
    visibility: hidden !important;
  }
}

body {
  min-height: 100%;
}

.section {
  padding: 4rem 1.5rem;
}
.section--try-now {
  padding-bottom: 0;
}
footer.app-footer {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  background-color: #F2F2F2;
  margin-top: -20px;
  background-position: 40% 0%;
  background-repeat: no-repeat;
  .menu-list {
    display: flex;
    margin-left: -0.8rem;
  }
}
.has-front-bg {
  background: url('~@/assets/homepage/header-bg.svg');
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 80% center;
}
.replace-top {
  /*align-items: flex-start !important;*/
  /*padding-top: 8rem !important;*/
}

.app-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.page--front {
  h2.title {
    padding-bottom: 5rem;
  }
}

.section--services {
  padding-top: 6rem;
  background: url('~@/assets/homepage/middlebg.svg') #FFF;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right 90%;

  .columns {
    margin: 6rem 0;
    align-items: center;
    img {
      max-height: 380px;
      width: auto;
    }
  }
}

.section--try-now {
  margin-top: 5rem;
  .columns {
    align-items: center;
  }
}

.page--front {
  .is-size-3 {
    font-weight: 800;
  }
}
.page--front .tag:not(body) {
  font-size: 1rem;
}

.section-why {
  .box {
    padding: 4rem 1.5rem;
    min-height: 454px;
  }

  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin: auto auto .5rem auto;
  }
}



.scroll-wrapper {
  /*background: -webkit-linear-gradient(right, #c56f82, #497ccb);*/
  /*background: linear-gradient(270deg, #c56f82, #497ccb);*/
  /*height: 100vh;*/
  /*text-align: center;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  opacity: 0;
  -webkit-animation: appear 2s forwards;
  animation: appear 2s forwards;
  animation-delay: 2s;
  position: relative;
}

/* -------------------------------------------------------------------------
   begin Scroll Down Button
 * ------------------------------------------------------------------------- */
button.scroll_down {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 50px;
  width: 30px;
  bottom: -230px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}
button.scroll_down:before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: scroll_down_btn-animation 2s infinite;
  animation: scroll_down_btn-animation 2s infinite;
  box-sizing: border-box;
}

.notices .is-warning a {
  color: #212121;
  font-weight: 600;
  text-decoration: underline;
}

.notification > .delete {
  right: 0.75rem;
  position: absolute;
  top: 0.75rem;
  transform: scale(1.2);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scroll_down_btn-animation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1280px) {
  .section--services {
    background-position: right 0%;
  }
  .section-why {
    .column {
      margin: 0 !important;
    }
  }
}
@media (max-width: 1000px) {
  .section--services {
    background-position: right 10%;
    background-size: 70%;
  }

  button.scroll_down {
    border: 2px solid #c7c7c7;
  }
  button.scroll_down:before {
    background-color: #c7c7c7;
  }
}

@media (max-width: 320px) {
  body {
    max-width: 320px !important;
    overflow: hidden !important;
  }
}

@media (max-width: 768px) {
  .section-why .box {
    padding: 3rem 1rem;
    min-height: auto;
  }
  .has-front-bg {
    background-position: right 64%;
  }
  .scroll-wrapper {
    display: none;
  }
  .section--services {
    .column {
      text-align: left !important;
    }
  }

  .replace-top {
    padding-top: 8rem !important;
  }


  .reverse-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}
@media (max-width: 500px) {
  .is-fullwidth-mobile {
    width: 100%;
  }
  .mb-2-mobile {
    margin-bottom: 0.5rem !important;
  }
}


</style>
