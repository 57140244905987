<template lang="pug">
  .pricing-table(:class="{ selected }")
    .pricing-plan(
      :class="[{ selected: selected === offer.uid, 'is-active': highlight === offer.uid  }, offer.classes]"
      v-for="offer in displayedOffers.filter(o => o.uid == 'free')")
      .plan-header
        h2.has-text-grey(v-html="offer.name")
        span.ribbon.has-text-weight-bold(v-if="offer.discountText")
          | {{  offer.discountText }}
      .plan-price
        h3.plan-price-amount.is-size-3.has-text-grey
          span.plan-price-currency.text-main €
          | {{  parseInt(offer.price) }}
          sup(v-if="offer.cents")
            | .{{ offer.cents }}
        span.has-text-grey(v-if="!offer.duration")
          | /month
        span.sub-price(v-if="offer.duration && offer.uid != 'free'")
          | for {{ offer.duration }}
      .plan-items.mx-2
        b-tooltip.plan-item(
          :label='item == "Payment system" ? tooltip : ""'
          v-for="item in offer.includes"
          type='is-dark'
          :animated='true'
          multilined
          position='is-top')
          b-icon.has-text-grey(icon="check" :type="offer.classes")
          span(v-html="item")
          b-icon(icon="info" custom-size="mdi-18px" v-if="item == 'Payment system'" :type="offer.classes")
      div.mt-auto.mb-4
        button.button.mt-5.is-outlined.is-dark.opacity-8(@click="$emit('plan-chosen', offer.uid);selected=offer.uid")
          | Choose

    .pricing-plan(
      v-if="creditOffer"
      :class="[{ selected: selected === 'credits', 'is-active': highlight === 'credits'  }]")
      .plan-header
        h2 Pay as you go
      .plan-price
        h3.plan-price-amount.is-size-3.has-text-dark
          span.plan-price-currency.text-main €
          | {{  parseInt(creditOffer.prices[creditsValue]) }}
        vue-slider.mx-2.my-2(v-model="creditsValue" :data="creditsOptions" :adsorb="true" tooltip-placement="right")
      .plan-items.mx-2
        .plan-item
          b-icon(icon="check" :type="creditOffer.classes")
          span {{creditsValue}} lessons
        b-tooltip.plan-item(
          :label='item == "Payment system" ? tooltip : ""'
          v-for="item in creditOffer.includes"
          :type="creditOffer.classes"
          multilined
          :animated='true'
          position='is-top')
          b-icon(icon="check" :type="creditOffer.classes")
          span(v-html="item")
          b-icon(icon="info" custom-size="mdi-18px" v-if="item == 'Payment system'" :type="creditOffer.classes")
      div.mb-4.mt-auto
        button.button.mt-5.is-dark(@click="$emit('plan-chosen', creditOffer.uids[creditsValue]);selected=creditOffer.uid")
          | Choose

    .pricing-plan(
      :class="[{ selected: selected === offer.uid, 'is-active': highlight === offer.uid  }, offer.classes]"
      v-for="offer in displayedOffers.filter(o => o.uid.includes('premium') || o.uid.includes('proplus') || o.uid.includes('basic'))")
      span.ribbon.has-text-weight-bold(v-if="offer.discountText")
        | {{  offer.discountText }}
      .plan-header
        h2(
          v-html="offer.name"
          :class="{'has-text-primary': offer.uid == 'premium_monthly', 'has-text-warning': offer.uid == 'basic_monthly', 'has-text-grey': offer.uid == 'premium_yearly'}"
        )
      .plan-price.mb-2
        h3.plan-price-amount.is-size-3(
          :class="{'has-text-grey': offer.uid == 'premium_yearly', 'has-text-warning': offer.uid == 'basic_monthly', 'has-text-primary': offer.uid == 'premium_monthly'}"
        )
          span.plan-price-currency.text-main €
          | {{  parseInt(offer.price) }}
          sup(v-if="offer.cents")
            | .{{ offer.cents }}
        span.has-text-grey(v-if="!offer.duration")
          | /{{ offer.uid.includes("yearly") ? "year" : "month" }}
        span.sub-price(v-if="offer.duration && offer.uid != 'free'")
          | for {{ offer.duration }}
      .plan-items.mx-2.mt-5
        b-tooltip.plan-item(
          :label='item.indexOf("&nbsp;") !== -1 ? tooltip : ""'
          v-for="item in offer.includes"
          :type="offer.classes"
          :animated='true'
          multilined
          position='is-top')
          b-icon(icon="check" :type="offer.classes")
          span(v-html="item")
          b-icon(icon="info" custom-size="mdi-18px" v-if="item.indexOf('&nbsp;') !== -1" :type="offer.classes")
      div.mb-4.mt-auto
        button.button.mt-5(
          @click="$emit('plan-chosen', offer.uid);selected=offer.uid"
          :class="{'is-warning': offer.uid == 'basic_monthly', 'is-primary': offer.uid == 'premium_monthly', 'is-grey': offer.uid == 'premium_yearly'}"
        )
          | Choose

</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
  name: 'PricingTable',
  components: {
    VueSlider
  },
  props: [
    'highlight',
    'offers'
  ],
  computed: {
    creditOffer() {
      return this.offers.find(offer => offer.uid == 'credits')
    },
    displayedOffers() {
      return this.offers.filter(offer => offer.public)
    }
  },
  data() {
    return {
      filteredYearly: false,
      selected: null,
      creditsValue: 50,
      creditsOptions: [10,50,150],
      // tooltip: '2% Platform fee\n' + '+\n' + 'Europe: 1.4% + €0.25 Stripe fee\n' + 'US: 2.9% + 30¢ Stripe fee'
      tooltip: 'You get 100% of the transaction minus the Stripe fees (best on the market) - EU cards: 1.4% fee + 0,25€ - US cards: 2.9% fee + 30¢'
    }
  },
}
</script>

<style lang="scss">
@import 'colors';

.pricing-table .pricing-plan.is-active.is-promotion {
  box-shadow: 0 8px 22px 0 rgba(14, 154, 241, 0.36);
}
.pricing-table {
  position: relative;
  .button-plan-toggle {
    position: absolute;
    top: -60px;
    right: 0;
  }
  div[id^="product-component"] {
    max-width: 240px;
    margin: -12px auto 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 39px;
  }
  .plan-header {position: relative}
  span.ribbon {
    padding: 4px 8px;
    font-size: 15px;
    display: inline-block;
    width: 50px;
    margin: auto;
  }
  .pricing-plan {
    border-radius: 10px;
  }

  i.mdi {
    font-weight: 600;
  }

  .pricing-plan .plan-price .plan-price-amount .plan-price-currency.text-main {
    vertical-align: baseline !important;
    color: lighten(#4a4a4a, 40);
  }

  .plan-price-amount {
    display: inline-block;
    vertical-align: baseline;
    color: lighten(#4a4a4a, 40);
    font-weight: 400 !important;
  }

  .pricing-plan .plan-item {
    align-items: center;
    text-align: left;
    display: flex;
    padding: 0.2em;
    color: $primaryDarker;
    font-size: 1rem;
    .tooltip-trigger {
      text-align: left;
      display: flex;
    }
  }
  .is-danger span.ribbon {
    background-color: $danger;
    color: #FFF;
  }
  .is-primary span.ribbon {
    background-color: $primary;
    color: #FFF;
    width: auto;
  }
  .is-secondary span.ribbon {
    background-color: $midGray;
    color: #FFF;
    width: auto;
  }
  span.sub-price {
    display: block;
    margin-top: -10px;
    font-weight: 700;
  }

  // Highlighted plan

  .pricing-plan.is-active.is-primary {
    background-color: #FFF;
    position: relative;
    z-index: 10;

    &:before {
      content: ' ';
      background: url("~@/assets/v3/pricing-bg.svg") no-repeat;
      width: 112%;
      height: 70px;
      background-size: contain;
      margin-top: -38px;
      margin-left: -6%;
      position: absolute;
      z-index: 9;
      @media (min-width: 1300px) {
        margin-top: -38px;
      }
      @media (min-width: 1400px) {
        margin-top: -38px;
      }
      @media (min-width: 1600px) {
        width: 112%;
        height: 70px;
        margin-top: -46px;
        margin-left: -6%;
      }
    }

  }
}
</style>
